import AdminHeader from "../components/AdminHeader";
import React, { useState, useEffect } from "react";
import { ref, getDatabase, get, set, onValue } from "firebase/database";
import { formatDateStamp, formatDateLong } from "../utils/dateFormatter";
import CloseIcon from "@mui/icons-material/Close";

export default function AdminBreakfast({ houseList, setTopic }) {
  const db = getDatabase();
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("Today");
  const [breakfastItems, setBreakfastItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const fetchRequests = async (house, date) => {
    const snapshot = await get(
      ref(db, `/breakfast/${house}/${date}/selection`)
    );
    if (snapshot.exists()) {
      const data = snapshot.val();
      let requests = [];
      let customRequest = "";

      if (data.nothingNeeded) {
        requests = ["Nothing needed"];
      } else {
        requests = Object.entries(data)
          .filter(([key, value]) => key !== "customRequest" && value)
          .map(([key]) => key);
      }

      customRequest = data.customRequest || "";

      return { requests, customRequest };
    } else {
      return { requests: ["No requests submitted"], customRequest: "" };
    }
  };

  const fetchData = async () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const requests = await Promise.all(
      houseList.map(async (house) => {
        const todayRequest = await fetchRequests(house, formatDateStamp(today));
        const tomorrowRequest = await fetchRequests(
          house,
          formatDateStamp(tomorrow)
        );

        return {
          house,
          today: todayRequest.requests,
          todayCustom: todayRequest.customRequest,
          tomorrow: tomorrowRequest.requests,
          tomorrowCustom: tomorrowRequest.customRequest,
        };
      })
    );
    setData(requests);
  };

  useEffect(() => {
    const refreshData = () => {
      fetchData(); // Fetch the latest data when the database changes
    };

    // Listen for changes at the '/breakfast/' path
    const breakfastRef = ref(db, `/breakfast/`);
    const unsubscribe = onValue(breakfastRef, refreshData);

    // Cleanup: stop listening when the component is unmounted
    return () => {
      unsubscribe(); // Remove the listener to prevent memory leaks
    };
  }, []); // Ensure dependencies are in place

  const fetchBreakfastItems = async () => {
    const snapshot = await get(ref(db, "/breakfastItems"));
    if (snapshot.exists()) {
      const items = snapshot.val();
      const sortedItems = items.sort((a, b) => a.localeCompare(b));
      setBreakfastItems(sortedItems);
    }
  };

  const handleRemoveItem = async (item) => {
    const updatedItems = breakfastItems.filter((i) => i !== item);
    setBreakfastItems(updatedItems);
    await set(ref(db, "/breakfastItems"), updatedItems);
  };

  const handleAddItem = async () => {
    if (newItem.trim() === "") {
      return;
    }
    const updatedItems = [...breakfastItems, newItem.trim()];
    const sortedItems = updatedItems.sort((a, b) => a.localeCompare(b));
    setBreakfastItems(sortedItems);
    await set(ref(db, "/breakfastItems"), sortedItems);
    setNewItem("");
  };

  useEffect(() => {
    fetchData();
    fetchBreakfastItems();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "Today":
        return (
          <div>
            <p className="w-full text-center py-3 italic">Today {formatDateLong(new Date())}</p>
            {data.map((houseData) => (
              <div
                key={houseData.house}
                className="p-4 bg-white shadow-md text-sm my-1"
              >
                <h3 className="font-semibold text-sky-500 mb-2">
                  {houseData.house}
                </h3>
                <ul className="text-gray-700">
                  {houseData.today.length > 0 ? (
                    <li>{houseData.today.join(", ")}</li>
                  ) : (
                    <li>No requests</li>
                  )}
                  {houseData.todayCustom && (
                    <li className="my-2">
                      <strong>Comment: </strong>
                      {houseData.todayCustom}
                    </li>
                  )}
                </ul>
              </div>
            ))}
          </div>
        );
      case "Tomorrow":
        return (
          <div>
            <p className="w-full text-center py-3 italic">
              Tomorrow {formatDateLong(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))}
            </p>
            {data.map((houseData) => (
              <div
                key={houseData.house}
                className="p-4 bg-white shadow-md text-sm my-1"
              >
                <h3 className="font-semibold text-sky-500 mb-2">
                  {houseData.house}
                </h3>
                <ul className="text-gray-700">
                  {houseData.tomorrow.length > 0 ? (
                    <li>{houseData.tomorrow.join(", ")}</li>
                  ) : (
                    <li>No requests</li>
                  )}
                  {houseData.tomorrowCustom && (
                    <li className="my-2">
                      <strong>Comment: </strong>
                      {houseData.tomorrowCustom}
                    </li>
                  )}
                </ul>
              </div>
            ))}
          </div>
        );
      case "Edit":
        return (
          <div>
            <p className="w-full py-5 ml-3 italic">Add new breakfast item</p>
            <div className="flex flex-row">
              <input
                type="text"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                placeholder="Add new item..."
                className="border p-2 w-5/6"
              />
              <button
                className="bg-sky-600 text-white p-2 w-1/6"
                onClick={handleAddItem}
              >
                Add
              </button>
            </div>
            <p className="w-full py-5 ml-3 italic">Current Selection</p>
            {breakfastItems.map((item) => (
              <div
                key={item}
                className="p-4 bg-white shadow-md text-sm my-1 flex justify-between"
              >
                <span>{item}</span>
                <CloseIcon
                  className="text-red-500 cursor-pointer"
                  onClick={() => handleRemoveItem(item)}
                />
              </div>
            ))}

          </div>
        );
      default:
        return <div>Invalid tab selected</div>;
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <AdminHeader title={"breakfast"} setTopic={setTopic} />
      <div className="flex justify-around bg-white p-4">
        <button
          className={`text-sky-600 ${activeTab === "Today" ? "font-bold" : ""}`}
          onClick={() => setActiveTab("Today")}
        >
          Today
        </button>
        <button
          className={`text-sky-600 ${activeTab === "Tomorrow" ? "font-bold" : ""}`}
          onClick={() => setActiveTab("Tomorrow")}
        >
          Tomorrow
        </button>
        <button
          className={`text-sky-600 ${activeTab === "Edit" ? "font-bold" : ""}`}
          onClick={() => setActiveTab("Edit")}
        >
          Edit
        </button>
      </div>
      {renderContent()}
    </div>
  );
}
