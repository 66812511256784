import React, { memo } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function Item({ ID, description, unitPrice, selection, removeItem, addItem }) {
  const checkQuantitySelected = (targetID) => {
    const selectedItem = selection.find((item) => item.ID === targetID);
    if (!selectedItem) return 0;
    return selectedItem.quantity;
  };

  return (
    <div className="w-full flex flex-row p-1 my-5 justify-between">
      <div className="flex flex-col w-2/3">
        <p className="uppercase text-sm">{description}</p>
        <p className="text-sm">{unitPrice} €</p>
      </div>
      <div className="flex flex-row w-1/3 max-w-[200px] items-center justify-around">
        <button
          onClick={() => {
            removeItem(ID);
          }}
          disabled={checkQuantitySelected(ID) === 0}
        >
          {" "}
          <RemoveIcon
            style={{ fontSize: "25px" }}
            className="hover:cursor-pointer border border-black rounded-full"
          />
        </button>
        <p className={`${checkQuantitySelected(ID) > 0 ? "font-bold" : ""}`}>
          {checkQuantitySelected(ID)}
        </p>
        <button
          onClick={() => {
            addItem(ID);
          }}
        >
          <AddIcon
            style={{ fontSize: "25px" }}
            className="hover:cursor-pointer border bg-black text-white border-black rounded-full"
          />
        </button>
      </div>
    </div>
  );
}

export default memo(Item);
