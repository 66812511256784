import AdminHeader from "../components/AdminHeader";
import React, { useState, useEffect } from "react";
import { ref, getDatabase, get } from "firebase/database";
import { formatDateStamp, formatDateLong } from "../utils/dateFormatter";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AdminCleaning({ houseList, setTopic }) {
  const db = getDatabase();

  const [data, setData] = useState([]);
  const [showToday, setShowToday] = useState(true);
  const [showTomorrow, setShowTomorrow] = useState(true);

  const fetchRequests = async (house, date) => {
    const snapshot = await get(ref(db, `/cleaning/${house}/${date}`));
    if (snapshot.exists()) {
      const data = snapshot.val();
      if (data.noDisturb) {
        return ["Do not disturb"];
      } else {
        // Convert the object to an array of requested items
        const requests = Object.entries(data)
          .filter(([key, value]) => value)
          .map(([key]) => {
            if (key === "message") {
              return `Message: ${data.message}`;
            } else if (key === "timeRange") {
              return `Time range: ${data.timeRange[0]} - ${data.timeRange[1]}h`;
            } else {
              // Transform the key into a readable format
              let readableKey = key
                .split(/(?=[A-Z])/)
                .join(" ")
                .toLowerCase();
              readableKey =
                readableKey.charAt(0).toUpperCase() + readableKey.slice(1);
              return readableKey;
            }
          });
        return requests.length > 0 ? requests : ["Nothing needed"];
      }
    } else {
      return ["Nothing filled in"];
    }
  };

  const fetchData = async () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const requests = await Promise.all(
      houseList.map(async (house) => {
        const todayRequest = await fetchRequests(house, formatDateStamp(today));
        const tomorrowRequest = await fetchRequests(
          house,
          formatDateStamp(tomorrow)
        );

        return {
          house,
          today: todayRequest,
          tomorrow: tomorrowRequest,
        };
      })
    );

    setData(requests);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleToday = () => {
    setShowToday(!showToday);
  };

  const toggleTomorrow = () => {
    setShowTomorrow(!showTomorrow);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <AdminHeader title={"cleaning"} setTopic={setTopic} />

      <div className="mt-6 px-5">
        <button
          className="flex items-center text-sky-600 pl-2"
          onClick={toggleToday}
        >
          Today {formatDateLong(new Date())}
          {showToday ? (
            <ExpandLessIcon className="text-gray-500 focus:outline-none ml-2" />
          ) : (
            <ExpandMoreIcon className="text-gray-500 focus:outline-none ml-2" />
          )}
        </button>
        {showToday && (
          <div className="mt-2 space-y-4">
            {data.map((houseData) => (
              <div
                key={houseData.house}
                className="p-4 bg-white shadow-md rounded-md text-sm"
              >
                <h3 className="font-semibold text-sky-500">
                  {houseData.house}
                </h3>
                <ul className="text-gray-700">
                  {houseData.today.length > 0 ? (
                    houseData.today.map((request, index) => (
                      <li key={index}>{request}</li>
                    ))
                  ) : (
                    <li>No requests</li>
                  )}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="mt-16 px-5">
        <button
          className="flex items-center text-sky-600 pl-2"
          onClick={toggleTomorrow}
        >
          Tomorrow{" "}
          {formatDateLong(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))}
          {showTomorrow ? (
            <ExpandLessIcon className="text-gray-500 focus:outline-none ml-2" />
          ) : (
            <ExpandMoreIcon className="text-gray-500 focus:outline-none ml-2" />
          )}
        </button>
        {showTomorrow && (
          <div className="mt-2 space-y-4">
            {data.map((houseData) => (
              <div
                key={houseData.house}
                className="p-4 bg-white shadow-md rounded-md text-sm"
              >
                <h3 className="font-semibold text-sky-500">
                  {houseData.house}
                </h3>
                <ul className="text-gray-700">
                  {houseData.tomorrow.length > 0 ? (
                    houseData.tomorrow.map((request, index) => (
                      <li key={index}>{request}</li>
                    ))
                  ) : (
                    <li>No requests</li>
                  )}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
