import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function Login({ setAdminMode }) {
  const auth = getAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, []);

  async function handleLogin(e) {
    try {
      setError("");
      setLoading(true);

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          // const user = userCredential.user;
          // console.log(user);
          // ...
        })
        .catch((error) => {});
    } catch (e) {
      console.log(e);
      setError(e.message);
    }

    setLoading(false);
  }

  return (
    <div>
      {loading && <p className="p-5 max-w-xl w-full mx-auto">Loading...</p>}
      {!loading && (
        <div className="w-full p-5 mt-5 flex flex-col gap-y-4 max-w-xl mx-auto">
          <h1 className="text-2xl mb-10">Log in as Admin</h1>
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            className="w-full"
          />

          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete="current-password"
            className="w-full"
          />

          <div className="flex flex-row justify-between mt-5">
            <button
              className="border-2 border-HS-100 uppercase py-2 px-4 w-1/2"
              type="submit"
              disabled={loading}
              onClick={() => {
                setAdminMode(false);
                setError("");
              }}
            >
              Back
            </button>
            <button
              className="bg-sky-500 hover:bg-sky-600 text-white uppercase py-2 px-4 w-1/2"
              type="submit"
              disabled={loading}
              onClick={() => {
                handleLogin();
                setError("");
              }}
            >
              Log In
            </button>
          </div>
        </div>
      )}
      <p className="p-5 text-red-500 max-w-xl w-full mx-auto">{error}</p>
    </div>
  );
}
