import { useState, useRef } from "react";
import { getDatabase, ref, set, push } from "firebase/database";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import html2pdf from "html2pdf.js";
const dateFormat = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "numeric",
};

// Add the new ResetConfirmPopup component here
function ResetConfirmPopup({ open, onConfirm, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="reset-confirm-dialog"
      aria-describedby="reset-confirm-dialog-description"
    >
      <DialogTitle id="reset-confirm-dialog">{"Confirm Reset"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="reset-confirm-dialog-description">
          Are you sure you want to reset the house?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RemoveOrderPopup({ open, onConfirm, onClose }) {
  return (
    <div className="fixed inset-0 w-full h-full flex items-center justify-center backdrop-blur-md z-20">
      <div className="p-5 rounded-md shadow-md max-w-xl absolute top-5 bg-white w-full">
        <h1 className="pb-5 text-xl">Please confirm</h1>
        <div className="py-5">
          <p>Are you sure you want to remove this order?</p>
        </div>
        <div className="flex flex-row gap-x-3">
          <button className="border rounded-md border-sky-500 mx-auto py-2 px-4 w-1/2 uppercase" onClick={onClose}>
            CANCEL
          </button>
          <button className="rounded-md mx-auto py-2 px-4 w-1/2 uppercase bg-sky-500 text-white" onClick={onConfirm}>
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  );
}

export default function HouseManagement({ houses, setHouses }) {
  const [activeHouse, setActiveHouse] = useState(null);
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const [showRemoveOrderConfirm, setShowRemoveOrderConfirm] = useState(false);
  const [orderToRemove, setOrderToRemove] = useState(null);
  const PDFRef = useRef(null);
  const db = getDatabase();

  const handleHouseSelection = (event) => {
    setActiveHouse(houses.find((house) => house.name === event.target.value));
  };

  const saveAsPDF = () => {
    const element = PDFRef.current;
    const opt = {
      margin: 1,
      filename: activeHouse.name + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { before: "#details" },
    };
    html2pdf().set(opt).from(element).save();
  };

  const resetHouse = () => {
    // ADDING OLD ORDERS TO HISTORY
    const newHouseHistoryEntry = activeHouse;
    newHouseHistoryEntry.time = new Date().toISOString();
    const inventoryListRef = ref(db, `/shop/history/houses`);
    const newInventoryAction = push(inventoryListRef);
    const setNewEntryPromise = set(newInventoryAction, newHouseHistoryEntry);

    // UPDATING THE NEW LIST
    const newHouseList = houses.map((house) => {
      if (house.name === activeHouse.name) {
        const newHouse = { ...house }; // make a copy of the object
        delete newHouse.currentOrder; // remove the currentOrder property
        delete newHouse.time; // remove the currentOrder property
        return newHouse;
      }
      return house;
    });
    const setInventoryPromise = set(ref(db, `/shop/houses`), newHouseList);
    setHouses(newHouseList);

    Promise.all([setInventoryPromise, setNewEntryPromise])
      .then(() => {
        setActiveHouse(null);
      })
      .catch((error) => {
        console.log(error);
      });
    // Hide the reset confirmation popup after reset
    setShowResetConfirm(false);
  };

  const handleReset = () => {
    setShowResetConfirm(true);
  };

  const handleRemoveOrder = (order) => {
    setOrderToRemove(order);
    setShowRemoveOrderConfirm(true);
  };

  const confirmRemoveOrder = () => {
    if (!orderToRemove) return;
    const updatedOrders = activeHouse.currentOrder.filter((order) => order.time !== orderToRemove.time);
    const updatedHouse = { ...activeHouse, currentOrder: updatedOrders };
    const updatedHouses = houses.map((house) => (house.name === activeHouse.name ? updatedHouse : house));

    set(ref(db, `/shop/houses`), updatedHouses)
      .then(() => {
        setHouses(updatedHouses);
        setActiveHouse(updatedHouse);
      })
      .catch((error) => console.log(error));

    setShowRemoveOrderConfirm(false);
  };

  const getSummaryFromOrders = () => {
    if (!activeHouse) return <p>No orders</p>;

    let itemsMap = {};
    let totalAmountWithoutVAT = 0;
    let totalAmount = 0;
    let totalVAT = 0;

    activeHouse.currentOrder.forEach((order) => {
      order.items.forEach((item) => {
        let amount = item.quantity * item.unitPrice;
        let VAT = (amount * item.VAT) / 100;

        if (itemsMap[item.ID]) {
          itemsMap[item.ID].quantity += item.quantity;
          itemsMap[item.ID].amount += amount;
        } else {
          itemsMap[item.ID] = {
            quantity: item.quantity,
            amount: amount,
            description: item.description,
          };
        }

        totalAmount += amount;
        totalVAT += VAT;
      });
    });

    totalAmountWithoutVAT = totalAmount - totalVAT;

    return (
      <div>
        <h1 className="uppercase pt-10 pb-5 text-center">Summary for house {activeHouse?.name}</h1>
        <div className="p-2 bg-HS-500 rounded-lg shadow-lg bg-white text-sm">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Item</th>
                <th className="px-4 py-2 text-center">Quantity</th>
                <th className="px-4 py-2 text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(itemsMap).map(([key, value]) => (
                <tr key={key} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="px-4 py-2 text-left">{value.description}</td>
                  <td className="px-4 py-2 text-center">{value.quantity} x</td>
                  <td className="px-4 py-2 text-right">{value.amount.toFixed(2)} €</td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="w-full text-right my-5">
            <tbody>
              <tr>
                <td className="px-4 py-2 text-HS-200">Total (excluding VAT):</td>
                <td className="px-4 py-2 text-HS-200">{totalAmountWithoutVAT.toFixed(2)} €</td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-HS-200">VAT:</td>
                <td className="px-4 py-2 text-HS-200">{totalVAT.toFixed(2)} €</td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-bold">Total (including VAT):</td>
                <td className="px-4 py-2 font-bold">{totalAmount.toFixed(2)} €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-xl w-full mx-auto">
      <ResetConfirmPopup open={showResetConfirm} onConfirm={resetHouse} onClose={() => setShowResetConfirm(false)} />
      {showRemoveOrderConfirm && (
        <RemoveOrderPopup
          open={showRemoveOrderConfirm}
          onConfirm={confirmRemoveOrder}
          onClose={() => setShowRemoveOrderConfirm(false)}
        />
      )}
      <div className="flex flex-row p-5">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">House</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={activeHouse ? activeHouse.name : ""}
            label="House"
            onChange={handleHouseSelection}
          >
            {houses.map((house) => (
              <MenuItem key={house.name} value={house.name}>
                {house.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {(!activeHouse?.currentOrder || activeHouse?.currentOrder.length === 0) && (
        <p className="italic text-center">No active orders</p>
      )}

      {activeHouse?.currentOrder && activeHouse.currentOrder.length > 0 && (
        <div className="px-5 pb-10 flex flex-col">
          <div className="flex flex-row justify-between gap-x-3">
            <button
              className="rounded-lg text-red-600 text-HS-400 px-3 py-3 mb-3 flex-grow border border-red-600"
              onClick={handleReset}
            >
              Reset House
            </button>
            <button
              className="rounded-lg bg-HS-100 text-HS-400 px-3 py-3 mb-3 flex-grow border border-gray-600"
              onClick={saveAsPDF}
            >
              Export to PDF
            </button>
          </div>

          <div ref={PDFRef}>
            {getSummaryFromOrders()}

            <h1 className="uppercase pt-10 pb-3 text-center" id="details">
              Detailed orders
            </h1>
            {activeHouse.currentOrder.map((order) => (
              <div key={order.time} className="my-2 p-5 bg-HS-500 rounded-lg shadow-lg bg-white flex flex-col">
                <div>
                  <p className="font-bold pb-3">{new Date(order.time).toLocaleDateString("en-GB", dateFormat)}</p>
                  <div className="pr-5">
                    {order.items &&
                      order.items.map((item) => (
                        <div key={item.ID} className="flex flex-row justify-between">
                          <p>{item.description}</p> <p>{item.quantity} x</p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex flex-row justify-end">
                  <button
                    className="rounded-lg text-red-600 text-HS-400 px-3 py-3 mt-5 border border-red-600"
                    onClick={() => handleRemoveOrder(order)}
                  >
                    Remove Order
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
