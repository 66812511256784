export function formatDateLong(date) {
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  const day = date.getDate();
  const suffix = getOrdinalSuffix(day);

  return formattedDate.replace(/(\d+)/, `$1${suffix}`);
}

function getOrdinalSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  const lastDigit = day % 10;

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function formatDateStamp(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
}

export function messageTimeStamp(stamp) {
  // return new Date(parseInt(stamp))
  //   .toLocaleString([], {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: false,
  //   })
  //   .replace(/(\d+)(?:st|nd|rd|th)/, "$1");

  const date = new Date(parseInt(stamp));
  return formatDateLong(date);
}
