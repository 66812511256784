import { Home, LocalCafe, LocalGroceryStore, Info } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { getAuth, signOut } from "firebase/auth";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

export default function Admin({ setTopic, setAdminMode }) {
  const handleSignout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // document.location.href = "/";
        setAdminMode(false);
        console.log("Signout succesfull");
        // Sign-out successful.
      })
      .catch((error) => {
        console.log("Signout failed");
        // An error happened.
      });
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="pt-10 text-xl font-bold text-center">
        <AdminPanelSettingsIcon fontSize="large" />
        <br />
        Admin board
      </h1>

      <div className="mt-16 grid grid-cols-1 gap-1 w-full max-w-md">
        <button
          className="p-3 flex flex-row items-center space-x-2 justify-between text-gray-600 bg-white shadow-md h-14"
          onClick={() => setTopic("breakfast")}
        >
          <LocalCafe />
          <p>Breakfast</p>
        </button>
        <button
          className="p-3 flex flex-row items-center space-x-2 justify-between text-gray-600 bg-white shadow-md h-14"
          onClick={() => setTopic("shop")}
        >
          <LocalGroceryStore />
          <p>Shop</p>
        </button>
        <button
          className="p-3 flex flex-row items-center space-x-2 justify-between text-gray-600 bg-white shadow-md h-14"
          onClick={() => setTopic("infoboard")}
        >
          <Info />
          <p>Info</p>
        </button>
        <button
          className="p-3 flex flex-row items-center space-x-2 justify-between text-gray-600 bg-white shadow-md h-14"
          onClick={() => setTopic("cleaning")}
        >
          <CleaningServicesIcon />
          <p>Cleaning</p>
        </button>

        <button
          onClick={() => setAdminMode(false)}
          className="p-3 flex flex-row items-center space-x-2 justify-between text-gray-600 bg-white shadow-md h-14 mt-10"
        >
          <Home />
          <p> Guest page </p>
        </button>

        <button
          onClick={handleSignout}
          className="p-3 flex flex-row items-center space-x-2 justify-between text-gray-600 bg-white shadow-md h-14"
        >
          <LogoutIcon />
          <p> Sign out </p>
        </button>
      </div>
    </div>
  );
}
