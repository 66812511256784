import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function AdminHeader({ setTopic, title }) {
  return (
    <div className="flex flex-row mb-5 px-5 py-3">
      <button
        onClick={() => {
          setTopic(null);
        }}
      >
        <ChevronLeftIcon
          style={{ fontSize: "35px" }}
          className=" text-HS-100 hover:cursor-pointer"
        />
      </button>

      <div className="flex flex-col p-3 ml-5">
        <h1 className="text-2xl">
          <span className="capitalize ">{title}</span>
        </h1>
        <p className="italic text-sm capitalize">admin</p>
      </div>
    </div>
  );
}
