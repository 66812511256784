import { useState, useEffect } from "react";
import Inventory from "./Inventory";
import HouseManagement from "./HouseManagement";
import AdminHeader from "../../components/AdminHeader";

import { ref, onValue, getDatabase } from "firebase/database";
import { Select, MenuItem } from "@mui/material";

export default function AdminShop({ setTopic }) {
  const db = getDatabase();
  const [mode, setMode] = useState("inventory");
  const [inventory, setInventory] = useState([]);
  const [houses, setHouses] = useState([]);

  useEffect(() => {
    const fetchDB = async () => {
      const inventoryRef = ref(db, "/shop");

      // listen for changes in the database
      const unsubscribe = onValue(
        inventoryRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const { inventory, houses } = snapshot.val();
            setInventory(inventory);
            setHouses(houses);
          }
        },
        (error) => {
          console.error(error);
        }
      );

      // make sure to unsubscribe when the component is unmounted
      return () => unsubscribe();
    };

    fetchDB();
  }, [db]);

  return (
    <div className="relative max-w-2xl mx-auto">
      <AdminHeader title={"shop"} setTopic={setTopic} />
      {/* NAV */}
      <div className="flex flex-row items-baseline justify-between px-5 absolute top-8 right-0 text-sm">
        <Select
          value={mode}
          onChange={(event) => setMode(event.target.value)}
          className="uppercase text-HS-400 bg-white shadow-md text-sm w-40"
          size="small"
        >
          <MenuItem value="inventory" size="small">
            Inventory
          </MenuItem>
          <MenuItem value="houseManagement" size="small">
            Check out
          </MenuItem>
        </Select>
      </div>
      <div className="flex flex-col flex-grow w-full">
        {/* INVENTORY */}
        {mode === "inventory" && (
          <Inventory inventory={inventory} houses={houses} />
        )}

        {/* HOUSE MANAGEMENT */}
        {mode === "houseManagement" && houses && (
          <HouseManagement houses={houses} setHouses={setHouses} />
        )}
      </div>
    </div>
  );
}
