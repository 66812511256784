import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function TopicHeader({ house, setHouse, setTopic, title }) {
  return (
    <div className="flex flex-row px-5 pt-5">
      <button
        onClick={() => {
          setHouse("");
          setTopic(null);
        }}
      >
        {" "}
        <ChevronLeftIcon
          style={{ fontSize: "35px" }}
          className=" text-HS-100 hover:cursor-pointer"
        />
      </button>

      <div className="flex flex-col p-3 ml-5">
        <h1 className="text-2xl">
          <span className="capitalize ">{title}</span>
        </h1>
        <p className="italic text-sm">
          House <span className="capitalize ">{house}</span>{" "}
        </p>
      </div>
    </div>
  );
}
