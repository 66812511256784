import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { LocalGroceryStore, Info } from "@mui/icons-material";
// import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import shopImg from "./media/shop.png";
// import tile from "./media/tile.png";

export default function Guest({ house, setHouse, houseList, topic, setTopic }) {
  const handleHouseSelection = (event) => {
    setHouse(event.target.value);
  };

  return (
    <div className="flex flex-col items-center">
      {/* <h1 className="py-10 text-xl font-bold text-center">
        <HomeIcon fontSize="large" />
        <br />
        Welcome!
      </h1> */}
      <img src={shopImg} alt="Shop" className="w-full max-w-sm" />

      {/* <div
        className="bg-repeat-x bg-contain w-full h-20 bg-opacity-50"
        style={{ backgroundImage: `url(${tile})` }}
      ></div> */}

      <p className="pt-5 text-HS-200 pb-5 text-center italic">
        1. First, select your house
      </p>
      <div className="flex flex-row w-full max-w-md mx-auto">
        <FormControl
          fullWidth
          sx={{
            height: "100%", // Ensure the FormControl matches the parent height
            "& .MuiOutlinedInput-root": {
              borderRadius: 0, // Remove rounded corners
              height: "100%", // Match height to parent
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 0, // Remove border outline
            },
          }}
        >
          <InputLabel id="simple-select-label">House</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={house ?? ""}
            label="House"
            className="bg-white shadow-md"
            onChange={handleHouseSelection}
          >
            {houseList.map((house) => (
              <MenuItem key={house} value={house} className="capitalize">
                {house}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <p className="pt-5 text-HS-200 pb-5 italic">
        2. Then, select the topic of interest
      </p>
      <div className="grid grid-cols-1 gap-1 w-full max-w-md pb-10">
        {/* <button
          className={`p-3 flex flex-row items-center space-x-2 justify-between h-16 ${house ? "bg-white shadow-md" : "text-gray-400 border-gray-400"
            }`}
          onClick={() => setTopic("breakfast")}
          disabled={!house}
        >
          <p> Breakfast </p>
          <LocalCafe />
        </button> */}
        <button
          className={`p-3 flex flex-row items-center space-x-2 justify-between h-16 ${
            house ? "bg-white shadow-md" : "text-gray-400 border-gray-400"
          }`}
          onClick={() => setTopic("shop")}
          disabled={!house}
        >
          <p> Shop </p>
          <LocalGroceryStore />
        </button>
        <button
          className={`p-3 flex flex-row items-center space-x-2 justify-between h-16 ${
            house ? "bg-white shadow-md" : "text-gray-400 border-gray-400"
          }`}
          onClick={() => setTopic("infoboard")}
          disabled={!house}
        >
          <p> Info </p>
          <Info />
        </button>
        {/* <button
          className={`p-3 flex flex-row items-center space-x-2 justify-between h-16 ${
            house ? "bg-white shadow-md" : "text-gray-400 border-gray-400"
          }`}
          onClick={() => setTopic("cleaning")}
          disabled={!house}
        >
          <p> Cleaning </p>
          <CleaningServicesIcon />
        </button> */}
      </div>
    </div>
  );
}
