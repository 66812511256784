import { useState } from "react";
import { getDatabase, ref, set, push } from "firebase/database";
import EditIcon from "@mui/icons-material/Edit";
import { TextField, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function Inventory({ inventory, houses }) {
  const db = getDatabase();
  const [itemUnderEdit, setItemUnderEdit] = useState(null);
  const [editMenuIsShown, setEditMenuIsShown] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const getCategories = () => {
    const categories = new Set();
    inventory.forEach((item) => categories.add(item.category));
    return Array.from(categories);
  };

  return (
    <div className="flex flex-col flex-grow w-full">
      <div className="flex flex-col max-w-xl w-full mx-auto px-5">
        {inventory?.length > 0 && (
          <div className="mb-28">
            {getCategories().map((category) => (
              <div key={category}>
                <button
                  className="flex justify-between items-center w-full pl-3 pr-5 py-3 my-3 uppercase text-left rounded-lg border shadow-md text-sm bg-white"
                  onClick={() =>
                    setActiveCategory(
                      activeCategory === category ? null : category
                    )
                  }
                >
                  <span className="ml-2">{category}</span>
                  {activeCategory === category ? (
                    <ExpandLessIcon style={{ fontSize: "35px" }} />
                  ) : (
                    <ExpandMoreIcon style={{ fontSize: "35px" }} />
                  )}
                </button>
                {activeCategory === category &&
                  inventory
                    .filter((item) => item.category === category)
                    .map((item) => (
                      <Item
                        key={item.ID}
                        item={item}
                        setItemUnderEdit={setItemUnderEdit}
                        setEditMenuIsShown={setEditMenuIsShown}
                      />
                    ))}
              </div>
            ))}
          </div>
        )}
        {!inventory && (
          <p className="italic text-center py-10">
            Empty inventory. Start by adding items.
          </p>
        )}
        <button
          className="bg-gray-800 rounded-full aspect-square text-white w-12 fixed right-4 bottom-4 shadow-2xl flex items-center justify-center select-none"
          onClick={() => {
            setItemUnderEdit(null);
            setEditMenuIsShown(true);
          }}
        >
          <span className="block leading-8 text-3xl select-none">+</span>
        </button>
      </div>

      {/* ITEM EDIT MENU */}
      {editMenuIsShown && (
        <EditItemMenu
          itemUnderEdit={itemUnderEdit}
          setItemUnderEdit={setItemUnderEdit}
          setEditMenuIsShown={setEditMenuIsShown}
          inventory={inventory}
          db={db}
        />
      )}
    </div>
  );
}

function Item({ item, setItemUnderEdit, setEditMenuIsShown }) {
  return (
    <div className="flex flex-row justify-between items-center p-2 pl-5 my-2 w-full border rounded-md bg-white">
      <div className="flex flex-col">
        <p className="overflow-hidden whitespace-nowrap overflow-ellipsis">
          {item.description}
        </p>
        <div className="flex flex-row gap-x-4 text-sm text-gray-600">
          <p className="text-HS-400 bg-HS-200 rounded-md">{item.unitPrice} €</p>
          <p
            className={`text-HS-400 bg-HS-200 rounded-md ${
              item.quantity < 1 ? "text-red-600 font-bold" : ""
            }`}
          >
            {item.quantity} x
          </p>
          <p className="text-HS-400 bg-HS-200 rounded-md">{item.VAT}% VAT</p>
        </div>
      </div>
      <button
        className="bg-HS-200 text-HS-400 w-10 flex justify-center items-center aspect-square"
        onClick={() => {
          setItemUnderEdit(item);
          setEditMenuIsShown(true);
        }}
      >
        <EditIcon style={{ fontSize: "25px" }} className="text-HS-400" />
      </button>
    </div>
  );
}

function EditItemMenu({
  itemUnderEdit,
  setItemUnderEdit,
  db,
  inventory,
  setEditMenuIsShown,
}) {
  const [ID, setID] = useState(itemUnderEdit?.ID || "");
  const [description, setDescription] = useState(
    itemUnderEdit?.description || ""
  );
  const [quantity, setQuantity] = useState(itemUnderEdit?.quantity || 0);
  const [unitPrice, setUnitPrice] = useState(itemUnderEdit?.unitPrice || "");
  const [VAT, setVAT] = useState(itemUnderEdit?.VAT || "");
  const [category, setCategory] = useState(itemUnderEdit?.category || "");
  const isNewItem = !itemUnderEdit;

  const handleConfirmation = () => {
    const timeStamp = new Date().toISOString();

    const previousValue = isNewItem
      ? {}
      : {
          ID: itemUnderEdit.ID,
          description: itemUnderEdit.description,
          quantity: itemUnderEdit.quantity,
          unitPrice: itemUnderEdit.unitPrice,
          VAT: itemUnderEdit.VAT,
          category: itemUnderEdit.category,
        };

    const newItem = {
      ID,
      description,
      quantity,
      unitPrice,
      VAT,
      category,
    };

    const newEntry = {
      time: timeStamp,
      previousValue,
      newValue: newItem,
      action: isNewItem ? "new item" : "updated item",
    };

    const inventoryListRef = ref(db, `/shop/history/inventory`);
    const newInventoryAction = push(inventoryListRef);

    const newInventory = inventory?.slice() ?? [];

    if (isNewItem) {
      newInventory.push(newItem);
    } else {
      newInventory[
        newInventory.findIndex((item) => item.ID === itemUnderEdit.ID)
      ] = newItem;
    }

    const setInventoryPromise = set(ref(db, `/shop/inventory`), newInventory);
    const setNewEntryPromise = set(newInventoryAction, newEntry);

    Promise.all([setInventoryPromise, setNewEntryPromise])
      .then(() => {
        setItemUnderEdit(null);
        setEditMenuIsShown(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    const timeStamp = new Date().toISOString();

    const previousValue = {
      ID: itemUnderEdit.ID,
      description: itemUnderEdit.description,
      quantity: itemUnderEdit.quantity,
      unitPrice: itemUnderEdit.unitPrice,
      VAT: itemUnderEdit.VAT,
      category: itemUnderEdit.category,
    };

    const newEntry = {
      time: timeStamp,
      previousValue,
      action: "deleted item",
    };

    const inventoryListRef = ref(db, `/shop/history/inventory`);
    const newInventoryAction = push(inventoryListRef);

    const newInventory = inventory?.slice() ?? [];

    const indexToRemove = inventory.findIndex(
      (item) => item.ID === itemUnderEdit.ID
    );

    newInventory.splice(indexToRemove, 1);

    const setInventoryPromise = set(ref(db, `/shop/inventory`), newInventory);
    const setNewEntryPromise = set(newInventoryAction, newEntry);

    Promise.all([setInventoryPromise, setNewEntryPromise])
      .then(() => {
        setItemUnderEdit(null);
        setEditMenuIsShown(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmationDisabled = () => {
    // Check if all required fields are filled in
    const fieldsAreFilledIn =
      description &&
      (quantity || quantity === 0) &&
      unitPrice &&
      VAT &&
      category;

    // Check if the ID is unique
    const IDIsUnique = isNewItem
      ? // If this is a new item, check if there is no existing item with the same ID in the inventory
        !inventory || !inventory.find((item) => item.ID === ID)
      : // If this is an existing item, it will not create an ID conflict
        true;

    // Return true if any required fields are missing or the ID is not unique
    return !(fieldsAreFilledIn && IDIsUnique);
  };

  return (
    <div className="fixed inset-0 w-full h-full flex items-center justify-center backdrop-blur-md">
      <div className="bg-white p-10 rounded-md shadow-md max-w-lg flex flex-col gap-y-4 relative">
        <button
          className="aspect-square w-10 rounded-full absolute right-3 top-3"
          onClick={() => {
            setEditMenuIsShown(false);
            setItemUnderEdit(null);
          }}
        >
          <CloseIcon style={{ fontSize: "25px" }} className="text-HS-100" />
        </button>

        <h1 className="text-HS-200 pb-5 font-bold">
          {isNewItem ? "New Item" : "Edit Item"}
        </h1>

        <TextField
          id="fullName"
          label="Full name"
          value={description}
          onChange={(event) => {
            setID(event.target.value.replace(/\s+/g, "-").toLowerCase());
            setDescription(event.target.value);
          }}
        />
        <TextField
          type="number"
          id="unitPrice"
          label="Unit Price"
          value={unitPrice}
          onChange={(event) => {
            setUnitPrice(event.target.value);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        <TextField
          type="number"
          id="quantity"
          label="Quantity"
          value={quantity}
          onChange={(event) => {
            setQuantity(event.target.value);
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="VAT-label">VAT</InputLabel>
          <Select
            labelId="VAT-label"
            id="VAT"
            value={VAT}
            label="VAT"
            onChange={(event) => {
              setVAT(event.target.value);
            }}
          >
            <MenuItem value={6}>6%</MenuItem>
            <MenuItem value={13}>13%</MenuItem>
            <MenuItem value={23}>23%</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="VAT-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            label="category"
            onChange={(event) => {
              setCategory(event.target.value);
            }}
          >
            <MenuItem value={"Grains and Pasta"}>Grains and Pasta</MenuItem>
            <MenuItem value={"Nuts and Seeds"}>Nuts and Seeds</MenuItem>
            <MenuItem value={"Beverages"}>Beverages</MenuItem>
            <MenuItem value={"Wines"}>Wines</MenuItem>
            <MenuItem value={"Sauces and Condiments"}>
              Sauces and Condiments
            </MenuItem>
            <MenuItem value={"Snacks and Ice Cream"}>
              Snacks and Ice Cream
            </MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>

        <p className="text-HS-200 italic text-sm ml-3">ID: {ID}</p>

        <div className="flex flex-row mt-5 justify-between">
          {!isNewItem && (
            <button
              className="text-red-600 text-HS-400 py-3 px-4 border border-red-600 rounded-lg uppercase"
              onClick={handleDelete}
            >
              Delete
            </button>
          )}
          <button
            className={`text-HS-400 py-3 px-4 rounded-lg uppercase border border-gray-600 ${
              isNewItem ? "w-full" : ""
            } ${confirmationDisabled() ? "bg-HS-300" : "bg-HS-100"}`}
            onClick={handleConfirmation}
            disabled={confirmationDisabled()}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
