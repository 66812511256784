import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./context/firebaseConfig";
// import backgroundImage from "./media/palmtree.jpg";

import Guest from "./Guest";
import Admin from "./Admin";
// import Cleaning from "./topics/Cleaning";
// import Breakfast from "./topics/Breakfast";
import Shop from "./topics/Shop";
import InfoBoard from "./topics/InfoBoard";

import AdminCleaning from "./admin/AdminCleaning";
import AdminBreakfast from "./admin/AdminBreakfast";
import AdminShop from "./admin/AdminShop/AdminShop";
import AdminInfoBoard from "./admin/AdminInfoBoard";

import Login from "./components/Login";

const houseList = [
  "Avocet",
  "Flamingo",
  "Azure",
  "Hoopoe",
  "Stork",
  "Oriole",
  "Spoonbill",
  "Fazenda",
];

export default function App() {
  const [house, setHouse] = useState("");
  const [topic, setTopic] = useState(null);
  const [user, setUser] = useState(null);
  const [adminMode, setAdminMode] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const houseParam = urlParams.get("house");
    const topicParam = urlParams.get("topic");

    setHouse(houseParam ? houseParam.toLowerCase() : null);
    setTopic(topicParam ? topicParam.toLowerCase() : null);

    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUser(user);
      }
      setAdminMode(window.location.pathname === "/admin");
      setLoading(false); // Mark loading as false when the initial setup is complete
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const renderComponent = () => {
    if (adminMode) {
      if (!user) {
        return <Login setAdminMode={setAdminMode} />;
      }

      switch (topic) {
        case "cleaning":
          return <AdminCleaning setTopic={setTopic} houseList={houseList} />;
        case "breakfast":
          return <AdminBreakfast setTopic={setTopic} houseList={houseList} />;
        case "shop":
          return <AdminShop setTopic={setTopic} houseList={houseList} />;
        case "infoboard":
          return <AdminInfoBoard setTopic={setTopic} houseList={houseList} />;
        default:
          return <Admin setTopic={setTopic} setAdminMode={setAdminMode} />;
      }
    } else {
      switch (topic) {
        // case "cleaning":
        //   return <Cleaning house={house} setHouse={setHouse} setTopic={setTopic} />;
        // case "breakfast":
        //   return <Breakfast house={house} setHouse={setHouse} setTopic={setTopic} />;
        case "shop":
          return <Shop house={house} setHouse={setHouse} setTopic={setTopic} />;
        case "infoboard":
          return (
            <InfoBoard house={house} setHouse={setHouse} setTopic={setTopic} />
          );
        default:
          return (
            <Guest
              house={house}
              setHouse={setHouse}
              houseList={houseList}
              topic={topic}
              setTopic={setTopic}
            />
          );
      }
    }
  };

  return (
    <div
      className="App mx-auto block min-h-screen bg-bgBeige"
      // style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundAttachment: "fixed" }}
    >
      {loading ? (
        // Render the loading spinner while loading is true
        <div className="flex items-center justify-center h-screen">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      ) : (
        // Render the component when loading is false
        renderComponent()
      )}
    </div>
  );
}
