import { useState, useEffect } from "react";
import { getDatabase, ref, set, onValue, off, remove } from "firebase/database";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AdminHeader from "../components/AdminHeader";

export default function AdminInfoBoard({ setTopic }) {
  // Use state hooks to manage the component's state
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  // Fetch existing messages from Firebase on component load
  useEffect(() => {
    // Get a reference to the Firebase Realtime Database
    const db = getDatabase();
    // Create a reference to the "infoboard" path in the database
    const infoboardRef = ref(db, "infoboard");

    // Define an event listener for new data in the "infoboard" path
    const handleNewData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newMessages = Object.keys(data)
          .filter((id) => id !== "archive") // Filter out the "archive" key
          .map((id) => ({
            id: Number(id),
            text: data[id],
          }));
        setMessages(newMessages);
      }
    };

    // Attach the event listener to the "infoboard" reference
    onValue(infoboardRef, handleNewData);

    // Clean up the event listener when the component unmounts
    return () => {
      off(infoboardRef, "value", handleNewData);
    };
  }, []);

  // Handler function that saves a new message to Firebase and updates state
  const handleConfirm = () => {
    // Get the current timestamp as the message ID
    const timeStamp = Date.now();
    // Get a reference to the Firebase Realtime Database
    const db = getDatabase();
    // Save the message to the "infoboard" path in the database
    set(ref(db, `infoboard/${timeStamp}`), message)
      .then(() => {
        // Clear the input field and update the list of messages in state
        setMessage("");
        setMessages([...messages, { id: timeStamp, text: message }]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handler function that removes a message from Firebase and updates state
  const handleRemove = (id) => {
    // Get a reference to the Firebase Realtime Database
    const db = getDatabase();
    // Find the message to be removed in the list of messages in state
    const message = messages.find((m) => m.id === id);
    // Get a reference to the message in the database
    const messageRef = ref(db, `infoboard/${message.id}`);
    // Remove the message from the database
    remove(messageRef)
      .then(() => {
        // Log a confirmation message and update the list of messages in state
        // console.log("Message removed:", message.text);
        setMessages(messages.filter((m) => m.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });

    // Save the message to the "infoboard/archive" path in the database
    set(ref(db, `infoboard/archive/${message.id}`), message.text)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="max-w-2xl mx-auto">
      <AdminHeader title={"info board"} setTopic={setTopic} />

      <div className="px-5">
        <p className="pl-2 pb-3">New message</p>
        {/* A multiline text input field for entering a new message */}
        <TextField
          id="message"
          label="Message"
          variant="outlined"
          multiline
          rows={3}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          className="w-full"
        />

        {/* A button for confirming and saving a new message */}
        <button
          className={`px-4 py-2 text-white rounded w-full mt-5 ${
            message ? "bg-sky-500 my-5" : "bg-sky-300 cursor-not-allowed my-5"
          }`}
          onClick={handleConfirm}
          disabled={!message}
        >
          Post message to board
        </button>

        <h1 className="pl-2 pb-3 mt-10"> Current messages</h1>

        {/* A list of previously saved messages */}
        <ul className="">
          {messages.length === 0 ? (
            <p className="italic">No messages posted</p>
          ) : (
            messages.map((m) => (
              <li
                key={m.id}
                className="bg-white shadow rounded-md px-4 mb-2 flex flex-row items-center justify-between text-sm"
              >
                {/* The text of the message */}
                <p className="mr-4 py-2">{m.text}</p>
                {/* A button for removing the message */}
                <button
                  onClick={() => handleRemove(m.id)}
                  className="text-red-500 p-2"
                >
                  <DeleteIcon />
                </button>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}
