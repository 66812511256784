import TopicHeader from "../components/TopicHeader";
import { ref, set, getDatabase, onValue } from "firebase/database";
import { useEffect, useState, useCallback, useMemo } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Item from "../components/Item";

export default function Shop({ house, setHouse, setTopic }) {
  const db = getDatabase();
  const [inventory, setInventory] = useState([]);
  const [houses, setHouses] = useState([]);
  const [selection, setSelection] = useState([]);
  const [confirmationIsShowing, setConfirmationIsShowing] = useState(false);
  const [greetingMessage, setGreetingMessage] = useState("welcome");
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const inventoryRef = ref(db, "shop/");
    try {
      onValue(
        inventoryRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setInventory(snapshot.val().inventory);
            setHouses(snapshot.val().houses);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategories = useCallback(() => {
    const categories = new Set();
    inventory.forEach((item) => categories.add(item.category));
    return Array.from(categories);
  }, [inventory]);

  const categories = useMemo(() => getCategories(), [getCategories]);

  const addItemToSelection = (targetID) => {
    // finding the item in the inventory
    const itemFromInventory = inventory.find(({ ID }) => ID === targetID);
    if (!itemFromInventory) {
      console.log("item not found in inventory!");
      return;
    }

    // selection part
    const updatedSelection = selection.map((item) => {
      if (item.ID === targetID) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });

    if (!updatedSelection.find((item) => item.ID === targetID)) {
      updatedSelection.push({
        ID: targetID,
        description: itemFromInventory.description,
        quantity: 1,
        unitPrice: itemFromInventory.unitPrice,
        VAT: itemFromInventory.VAT,
      });
    }

    setSelection(updatedSelection);
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    selection.forEach((item) => {
      totalPrice += item.quantity * item.unitPrice;
    });
    return totalPrice;
  };

  const getTotalNumberOfArticles = () => {
    let totalNoOfArticles = 0;
    selection.forEach((item) => {
      totalNoOfArticles += item.quantity;
    });
    return totalNoOfArticles;
  };

  const removeItemFromSelection = (targetID) => {
    // selection part
    const updatedSelection = selection
      .map((item) => {
        if (item.ID === targetID) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    setSelection(updatedSelection);
  };

  const handleConfirmation = useCallback(async () => {
    // adding the order to the right house...
    const updatedHouses = houses.map((houseFromList) => {
      const newOrder = { items: selection, time: new Date().toISOString() };

      if (houseFromList.name === house) {
        const currentOrder = houseFromList.currentOrder || [];
        const newCurrentOrder = [...currentOrder, newOrder];
        return {
          ...houseFromList,
          currentOrder: newCurrentOrder,
        };
      }
      return houseFromList;
    });

    // ... and update the database
    set(ref(db, `shop/houses`), updatedHouses)
      .then(() => {
        setHouses(updatedHouses);
      })
      .catch((error) => {
        // what to do on succesful datasave?
        console.log(error);
      });

    // removing items from the inventory
    const updatedInventory = inventory.map((inventoryItem) => {
      const selectedItem = selection.find((selectionItem) => selectionItem.ID === inventoryItem.ID);

      if (selectedItem) {
        const newQuantity = inventoryItem.quantity - selectedItem.quantity;
        return {
          ...inventoryItem,
          quantity: newQuantity,
        };
      }

      return inventoryItem;
    });

    // ... and update the database
    set(ref(db, `shop/inventory`), updatedInventory)
      .then(() => {
        setInventory(updatedInventory);
      })
      .catch((error) => {
        // what to do on succesful datasave?
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houses, inventory, selection]);

  return (
    <div className="flex flex-col flex-grow max-w-2xl mx-auto">
      <TopicHeader house={house} setHouse={setHouse} setTopic={setTopic} title={"shop"} />

      {greetingMessage === "goodbye" ? (
        <div className="p-5">
          <div className="items-center rounded-lg border bg-white shadow-lg text-sm">
            <h1 className="pt-8 font-bold text-center">Thank you for shopping with us.</h1>
            <p className="pt-5 pb-8 text-center">Have a nice day!</p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col flex-grow">
            {/* MENU */}
            <div className="flex-grow flex flex-col min-w-[310px] max-w-xl mx-auto w-full">
              {!inventory && <p className="p-5 text-center italic">The shop is empty for the moment.</p>}
              <div className="flex flex-col flex-grow border-b border-sky-300 p-5 overflow-scroll">
                {inventory?.length > 0 && (
                  <>
                    <div className="mb-10 px-5 flex flex-row">
                      <p>
                        Please confirm that you are ordering for house{" "}
                        <span className="font-bold text-sky-500">{house}</span>.
                        <br />
                        If not, please go back and select the correct house.
                      </p>
                    </div>
                    {categories.map((category) => (
                      <div key={category}>
                        <button
                          className="flex justify-between items-center w-full pl-3 pr-5 py-3 my-3 uppercase text-left rounded-lg border bg-white shadow-lg text-sm"
                          onClick={() => setActiveCategory(activeCategory === category ? null : category)}
                        >
                          <span className="ml-2">{category}</span>
                          {activeCategory === category ? (
                            <ExpandLessIcon style={{ fontSize: "35px" }} />
                          ) : (
                            <ExpandMoreIcon style={{ fontSize: "35px" }} />
                          )}
                        </button>
                        {activeCategory === category &&
                          inventory
                            .filter((item) => item.category === category)
                            .map((item) => (
                              <Item
                                key={item.ID}
                                ID={item.ID}
                                description={item.description}
                                unitPrice={item.unitPrice}
                                selection={selection}
                                removeItem={removeItemFromSelection}
                                addItem={addItemToSelection}
                              />
                            ))}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="flex flex-col p-5">
                <h1 className="font-bold text-xl">Total: {getTotalPrice()} €</h1>
                <p className="py-2">
                  {getTotalNumberOfArticles()} {getTotalNumberOfArticles() === 1 ? "article" : "articles"}
                </p>
                <button
                  className={`${
                    selection.length > 0 ? "bg-sky-500" : "bg-gray-300"
                  } px-4 py-2 text-white rounded w-full mt-5`}
                  disabled={selection.length === 0}
                  onClick={() => {
                    if (selection.length > 0) {
                      setConfirmationIsShowing(true);
                    }
                  }}
                >
                  CHECK OUT
                </button>
              </div>
            </div>
          </div>

          {/* CONFIRMATION */}
          {confirmationIsShowing && (
            <div className="fixed inset-0 w-full h-full flex items-center justify-center backdrop-blur-md">
              <div className="p-5 rounded-md shadow-md max-w-xl absolute top-5 bg-white w-full">
                <h1 className="pb-5 text-xl">Please confirm your selection</h1>
                <div className="py-5">
                  {selection?.map((item) => (
                    <div key={item.ID} className="flex flex-row justify-between">
                      <p>{item.description}</p> <p>{item.quantity} x</p>
                    </div>
                  ))}
                  <p className="font-bold py-5">Total: {getTotalPrice()} €</p>
                </div>

                <div className="flex flex-row gap-x-3">
                  <button
                    className="border rounded-md border-sky-500 mx-auto py-2 px-4 w-1/2 uppercase"
                    onClick={() => {
                      setConfirmationIsShowing(false);
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="rounded-md mx-auto py-2 px-4 w-1/2 uppercase bg-sky-500 text-white"
                    onClick={() => {
                      handleConfirmation();
                      setConfirmationIsShowing(false);
                      setGreetingMessage("goodbye");
                      setSelection([]);
                      setActiveCategory(null);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
