import { useState, useEffect } from "react";
import TopicHeader from "../components/TopicHeader";
import { getDatabase, ref, onValue, off } from "firebase/database";
import { messageTimeStamp } from "../utils/dateFormatter";

export default function InfoBoard({ house, setHouse, setTopic }) {
  // Use state hooks to manage the component's state
  const [messages, setMessages] = useState([]);

  // Fetch existing messages from Firebase on component load
  useEffect(() => {
    const db = getDatabase();
    const infoboardRef = ref(db, "infoboard");

    const handleNewData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newMessages = Object.keys(data)
          .filter((id) => id !== "archive")
          .map((id) => ({
            id: Number(id),
            text: data[id],
            timestamp: messageTimeStamp(id),
          }));
        setMessages(newMessages);
      }
    };

    onValue(infoboardRef, handleNewData);

    return () => {
      off(infoboardRef, "value", handleNewData);
    };
  }, []);

  return (
    <div className="max-w-2xl mx-auto">
      <TopicHeader
        house={house}
        setHouse={setHouse}
        setTopic={setTopic}
        title={"info"}
      />

      <div className="italic text-center text-sm px-5 mb-10">
        <p className="mt-10">
          You can find info and messages from the management team in our{" "}
          <a
            href="https://chat.whatsapp.com/CS7WeP2tKn4APLvsoBmc5U"
            className="text-sky-500 underline underline-offset-2"
          >
            WhatsApp group
          </a>
        </p>
      </div>

      {/* A list of previously saved messages */}
      {messages.length > 0 && (
        <ul className="mt-2 px-5">
          {messages.map((m) => (
            <li
              key={m.id}
              className="bg-white shadow rounded-md p-4 mb-2 flex flex-col border border-gray-300"
            >
              {/* The text of the message */}
              <p className="pb-3">{m.text}</p>
              <p className="text-xs italic text-gray-600">
                Posted on {m.timestamp}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
